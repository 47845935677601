/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    br: "br",
    strong: "strong"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, SideBySide, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/resound/linx/'], ['en', 'https://www.hear.com/hearing-aids/brands/resound/linx/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/resound/linx/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/resound/linx/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "one-of-the-best-technology-to-hear-better",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#one-of-the-best-technology-to-hear-better",
    "aria-label": "one of the best technology to hear better permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "One of the Best Technology to Hear Better"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/ReSound-linx.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Scientists spend their entire careers studying how we hear. The human auditory system has complex mechanisms that drive speech understanding. Restoring hearing to a damaged system requires brilliant engineering. Only ", React.createElement(_components.a, {
    href: "/hearing-aids/technology",
    className: "c-md-a"
  }, "sophisticated hearing technology"), " can compensate for hearing loss.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "The ReSound LiNX 3D and ReSound LiNX Quattro bring to you one of the best solutions in the industry of ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "modern hearing aids"), ". You’ll love the sound quality and noise reduction, rechargeable battery options, streaming, and ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/apps",
    className: "c-md-a"
  }, "phone app"), ".\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Models that fit ", React.createElement(_components.a, {
    href: "/hearing-aids/bte/",
    className: "c-md-a"
  }, "behind the ear"), " come in multiple sizes and seventeen attractive colors, so you’ll find a good match for your hearing loss with pleasing cosmetic results. If you prefer a ", React.createElement(_components.a, {
    href: "/hearing-aids/ite/",
    className: "c-md-a"
  }, "tiny in-the-ear"), " model, the LiNX 3D offers discrete options. These are not your grandmother’s bulky hearing aids.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hear-better-in-restaurants",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hear-better-in-restaurants",
    "aria-label": "hear better in restaurants permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hear better in Restaurants"), "\n", React.createElement(SideBySide, {
    copy: "",
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-chatting-in-restaurant.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many people with ", React.createElement(_components.a, {
    href: "/hearing-loss",
    className: "c-md-a"
  }, "hearing loss"), " report difficulty hearing friends and family in noisy restaurants. For years, hearing aids have advertised that they improve speech understanding in noise through various engineering feats. However, hearing aids have traditionally operated as separate devices. Your two ears work together to know where sounds come from and to separate speech from noise. Now, ", React.createElement(_components.strong, null, "ReSound Binaural Directionality uses ear-to-ear technology to allow two hearing aids to work together as one"), " unit. You’ll enjoy a true hearing system that mimics binaural hearing.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "power-your-day-with-rechargeable-batteries",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#power-your-day-with-rechargeable-batteries",
    "aria-label": "power your day with rechargeable batteries permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Power Your Day With Rechargeable Batteries"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/resound-device.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "The LiNX 3D may be used with Z power rechargeable batteries"), ". For even more convenience, consider the ReSound LiNX Quattro with the new Resound Charger. This small portable battery charger holds three charges. You’ll be able to charge batteries even if you aren’t near an electricity source. It takes just one hour to charge for 16 hours’ worth of battery life, and a full charge can last up to 30 hours. You won’t have to worry about buying a supply of expensive hearing aid batteries. It is a hassle to keep spare batteries on hand at all times.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "wirelessly-stream-sound-into-your-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#wirelessly-stream-sound-into-your-hearing-aids",
    "aria-label": "wirelessly stream sound into your hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Wirelessly Stream Sound Into Your Hearing Aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/ReSound-linx-lineup-colors.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have the ", React.createElement(_components.strong, null, "Apple iPhone or iPad, you can listen to phone calls and media through your hearing aids ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth",
    className: "c-md-a"
  }, "wirelessly without any additional accessories")), ". Android devices require the use of the ReSound Phone Clip Bluetooth accessory. Direct sound streaming provides crystal-clear sound to both hearing aids at the same time. You don’t have to worry about precisely how to hold the phone up to your ear.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "customize-your-hearing-with-apps",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customize-your-hearing-with-apps",
    "aria-label": "customize your hearing with apps permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customize Your Hearing With Apps"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/ReSound-Smart-app-2.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ReSound Smart 3D App for your smart phone allows you to ", React.createElement(_components.strong, null, "control volume and programs with a simple tap or swipe"), ". You can even boost the bass or treble separately, something that you cannot do with a traditional hearing aid volume control.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "You don’t have to go back to the provider’s office for every little fine tuning of your hearing aids. Your provider can ", React.createElement(_components.strong, null, "remotely send you new programs through your phone’s cell network"), ". This will save you multiple visits to your provider.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "If you suffer from annoying ringing in your ears (tinnitus), you can choose among ", React.createElement(_components.strong, null, "soothing sounds to help mask the tinnitus"), ".")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Should you lose your hearing aids, the app provides ", React.createElement(_components.strong, null, "a map of where you last left your aids"), ". The location-based hearing feature lets you save your hearing aid adjustments as a favorite. When you are back in that place, the aids will automatically adjust to your preferred settings. If you find just the right settings for the theater, you don’t have to remember those settings. Just ", React.createElement(_components.strong, null, "set the configuration as a favorite.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-a-hearing-aid-specialist-near-your-location",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-a-hearing-aid-specialist-near-your-location",
    "aria-label": "contact a hearing aid specialist near your location permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact a hearing aid specialist near your location"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We have hearing aid experts who are waiting to assist you. Sign up and they’ll answer your questions. ", React.createElement(_components.strong, null, "hear.com offers a 45 day risk-free trial of this technology with a 100% money-back guarantee"), ". Our experts will support you every step of the way."), "\n", React.createElement(LandingPageCta, {
    copy: "TRY RESOUND LINX NOW",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
